import React from 'react';
import styles from './../components/FormModal.module.css'; // Importando estilos locais

const FormModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  const handleClose = (e) => {
    e.stopPropagation();
    onClose();
  };

  const handleFormClick = (e) => {
    e.stopPropagation(); 
  };

  return (
    <div onClick={onClose}>
      <div className={styles.modal_dialog}>
        <div className={styles.modal_content} onClick={handleFormClick}>
          <div className={styles.modal_header}>
            <h3 className={styles.titleModalHeader}>Informe os dados abaixo para concluir o cadastro</h3>

            <button type="button" className={styles.close_button} onClick={handleClose}>Fechar</button>
          </div>
          <div className={styles.modal_body}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default FormModal;
