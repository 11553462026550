import React, { useState, useEffect } from 'react';
import NavBar from "../components/Navbar.js";
import GridPhotos from "../components/GridPhotos.js";
import FormPhotos from "../components/FormPhotos.js";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import RegisterBar from "../components/RegisterBar.js";
import styles from './../styles/CadastrarLivro.module.css'
import FormModal from "../components/FormModal.js";

export default function CadastrarFotografia({ token }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [onEdit, setOnEdit] = useState(null);

  const getPhotos = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}` // Adiciona o token ao cabeçalho da solicitação
        }
      }
      const res = await axios.get(`${process.env.REACT_APP_API_URL_LINK}/all-photos`, config);
      setPhotos(res.data.sort((a, b) => (a.nome > b.nome ? 1 : -1)));
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setOnEdit(null);
  };

  const handleEditModalOpen = () => {
    setIsModalOpen(true); // Função para abrir o modal
  }

  useEffect(() => {
    getPhotos();
  }, []);

  return (
    <div className={styles.body}>
      {/* <NavBar /> */}
      <RegisterBar />

      {/* <div className={styles.headerGridCard}>
        <h3>Fotografias cadastradas</h3>
        <button className={styles.buttonRegister} onClick={handleOpenModal}>Cadastrar fotografia</button>
      </div> */}

      <GridPhotos onEditClick={handleEditModalOpen} getPhotos={getPhotos} photos={photos} setPhotos={setPhotos} setOnEdit={setOnEdit} token={token} />

      <FormModal isOpen={isModalOpen} onClose={handleCloseModal} token={token} >
        <FormPhotos onEdit={onEdit} setOnEdit={setOnEdit} getPhotos={getPhotos} token={token} />
      </FormModal>

    </div>
  );
}
