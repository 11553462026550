import styles from './FormBooks.module.css';
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import Dropzone from 'dropzone';
import 'dropzone/dist/dropzone.css';
import { TbAlertCircleFilled } from 'react-icons/tb';
import Select from "react-select"
import CreatableSelect from "react-select/creatable"

const FormBooks = ({ getBooks, onEdit, setOnEdit, token }) => {
  const [file, setFile] = useState(null);

  const [selectedPublicacaoTipo, setSelectedPublicacaoTipo] = useState(null);
  const [selectedPublicacaoAreaConhecim, setSelectedPublicacaoAreaConhecim] = useState(null);
  const [selectedPublicacaoAno, setSelectedPublicacaoAno] = useState(null);
  const [selectedPublicacaoAutorNaoIny, setSelectedPublicacaoAutorNaoIny] = useState(null);
  const [selectedPublicacaoAtiva, setSelectedPublicacaoAtiva] = useState(null);


  const ref = useRef();

  const boolean_OPTIONS = [
    { value: 1, label: 'Sim' },
    { value: 0, label: 'Não' },
  ];

  const publicacao_autor_naoiny_OPTIONS = [
    { value: 1, label: 'Sim' },
    { value: 0, label: 'Não' },
  ];

  const publicacao_ativa_OPTIONS = [
    { value: 1, label: 'Sim' },
    { value: 0, label: 'Não' },
  ];

  const publicacao_area_conhecim_OPTIONS = [
    { value: 'Antropologia', label: 'Antropologia' },
    { value: 'Arqueologia', label: 'Arqueologia' },
    { value: 'História', label: 'História' },
    { value: 'Geografia e ciências ambientais', label: 'Geografia e ciências ambientais' },
    { value: 'Letras, linguística', label: 'Letras, linguística' },
    { value: 'Educação', label: 'Educação' },
    { value: 'Psicologia e ciências da saúde', label: 'Psicologia e ciências da saúde' },
    { value: 'Direito', label: 'Direito' },
    { value: 'Artes e cultura material', label: 'Artes e cultura material' },
    { value: 'Outras áreas', label: 'Outras áreas' },
    { value: 'Registros de cronistas, viajantes e outros relatos históricos', label: 'Registros de cronistas, viajantes e outros relatos históricos' },
  ];

  publicacao_area_conhecim_OPTIONS.sort((a, b) => a.label.localeCompare(b.label));


  const publicacao_tipo_OPTIONS = [
    { value: 'Artigo em periódico, jornal ou revista', label: 'Artigo em periódico, jornal ou revista' },
    { value: 'Trabalho de conclusão de curso', label: 'Trabalho de conclusão de curso' },
    { value: 'Dissertação de mestrado', label: 'Dissertação de mestrado' },
    { value: 'Tese de doutorado', label: 'Tese de doutorado' },
    { value: 'Livro', label: 'Livro' },
    { value: 'Capítulo de livro', label: 'Capítulo de livro' },
    { value: 'Anais de evento', label: 'Anais de evento' },
    { value: 'Cartilha, folheto, catálogo', label: 'Cartilha, folheto, catálogo' },
    { value: 'Livro didático', label: 'Livro didático' },
    { value: 'Relatórios e documentos', label: 'Relatórios e documentos' },
    { value: 'Outros', label: 'Outros' },
  ];

  publicacao_tipo_OPTIONS.sort((a, b) => a.label.localeCompare(b.label));

  const valorPadraoSim = { value: '1', label: 'Sim' }; // Define o valor padrão como '1'

  const getYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear; i >= currentYear - 200; i--) {
      years.push({ value: i, label: i });
    }
    return years;
  }

  useEffect(() => {
    if (onEdit) {
      const book = ref.current;
      // console.log(token)
      const selectedTipo = publicacao_tipo_OPTIONS.find(option => option.value === onEdit.publicacao_tipo);
      const selectedAreaConhecim = publicacao_area_conhecim_OPTIONS.find(option => option.value === onEdit.publicacao_area_conhecim);
      const selectedAtiva = publicacao_ativa_OPTIONS.find(option => option.value === onEdit.publicacao_ativa);
      const selectedNaoIny = publicacao_autor_naoiny_OPTIONS.find(option => option.value === onEdit.publicacao_autor_naoiny);
      const ano = getYears().find(option => option.value === onEdit.publicacao_ano);

      // console.log(`selectedNaoIny: ${JSON.stringify(selectedNaoIny)}`)
      // console.log(`selectedAtiva: ${JSON.stringify(selectedAtiva)}`)

      setSelectedPublicacaoTipo(selectedTipo);
      setSelectedPublicacaoAreaConhecim(selectedAreaConhecim);
      setSelectedPublicacaoAtiva(selectedAtiva);
      setSelectedPublicacaoAutorNaoIny(selectedNaoIny);
      setSelectedPublicacaoAno(ano);


      book.arquivo_nome.value = onEdit.arquivo_nome;
      book.autor_sobrenome.value = onEdit.autor_sobrenome;
      book.autor_nome.value = onEdit.autor_nome;
      book.publicacao_ano.value = onEdit.publicacao_ano;
      book.publicacao_titulo.value = onEdit.publicacao_titulo;
      book.publicacao_complemento.value = onEdit.publicacao_complemento;
      book.publicacao_tipo.value = onEdit.publicacao_tipo;
      book.publicacao_area_conhecim.value = onEdit.publicacao_area_conhecim;
      book.publicacao_autor_naoiny.value = onEdit.publicacao_autor_naoiny;
      book.publicacao_url_pdf.value = onEdit.publicacao_url_pdf;
      book.publicacao_ativa.value = onEdit.publicacao_ativa;
    }
  }, [onEdit]);


  useEffect(() => {
    const dropzone = new Dropzone("#file-dropzone", {
      url: '/', // URL de upload, se necessário
      maxFiles: 1, // Número máximo de arquivos que podem ser enviados
      acceptedFiles: '.pdf', // Tipos de arquivos aceitos
      init: function () {
        this.on('addedfile', function (file) {
          // Callback chamado quando um arquivo é adicionado
          console.log('Added file:', file);
          setFile(file);
        });

        this.on('uploadprogress', function (file, progress, bytesSent) {
          // Atualiza a barra de progresso com o progresso atual
          console.log('Upload progress:', progress);
          // Atualize a barra de progresso aqui com o valor de progress
        });

        this.on('totaluploadprogress', function (totalProgress) {
          // Atualiza a barra de progresso total com o progresso total
          console.log('Total upload progress:', totalProgress);
          // Atualize a barra de progresso total aqui com o valor de totalProgress
        });
      }
    });

    // Adicione estilos ou classes CSS personalizadas aqui
    return () => {
      dropzone.destroy(); // Limpa o Dropzone quando o componente é desmontado
    };
  }, []);


  const handleFileChange = (event) => {
    setFile(event.target.files[0]);

  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const book = ref.current;

    if (
      !book.arquivo_nome.value ||
      !book.autor_sobrenome.value ||
      !book.autor_nome.value ||
      !book.publicacao_ano.value ||
      !book.publicacao_titulo.value ||
      !book.publicacao_complemento.value ||
      !book.publicacao_tipo.value ||
      !book.publicacao_area_conhecim.value ||
      !book.publicacao_autor_naoiny.value ||
      !book.publicacao_ativa.value
    ) {
      return toast.warn("Preencha todos os campos!");
    }

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }

      const formData = new FormData();

      const values = {
        arquivo_nome: book.arquivo_nome.value,
        autor_sobrenome: book.autor_sobrenome.value,
        autor_nome: book.autor_nome.value,
        publicacao_ano: book.publicacao_ano.value,
        publicacao_titulo: book.publicacao_titulo.value,
        publicacao_complemento: book.publicacao_complemento.value,
        publicacao_tipo: book.publicacao_tipo.value,
        publicacao_area_conhecim: book.publicacao_area_conhecim.value,
        publicacao_autor_naoiny: book.publicacao_autor_naoiny.value,
        publicacao_url_pdf: book.publicacao_url_pdf.value,
        publicacao_ativa: book.publicacao_ativa.value
      };

      Object.entries(values).forEach(([key, value]) => {
        formData.append(key, value);
        // console.log(`key: ${key}, value: ${value}`)
      });

      if (file) {
        formData.append('file', file);
      }

      // console.log(`FormData: ${JSON.stringify(values)}`)

      let response;

      if (onEdit) {

        function logFormData(formData) {
          for (let [key, value] of formData.entries()) {
            if (value instanceof File) {
              console.log(`${key}: File -> ${value.name}, ${value.size}, ${value.type}`);
            } else {
              console.log(`${key}: ${value}`);
            }
          }
        }

        // Logar o FormData antes da requisição
        logFormData(formData);
        // console.log(`token 2:${JSON.stringify(config)}`)
        await axios
        response = await axios.put(`${process.env.REACT_APP_API_URL_LINK}/books/${onEdit.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`

          }

        }, config)
          .then(({ data }) => {
            toast.success(data)
            getBooks();
          })
          .catch((error) => {
            toast.error(error.response.data);
            console.error(`Erro durante a solicitação via formulário de publicação: ${error}`);
          });
      } else {
        // console.log(`config ${config}`)
        response = await axios.post(`${process.env.REACT_APP_API_URL_LINK}/books`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`
          }
        })
          .then(({ data }) => {
            toast.success(data)
            getBooks();
          })
          .catch((error) => {

            toast.error(error.response.data);
            console.error(error);

          });
      }

      // console.log(`selectedPublicacaoAtiva || publicacao_ativa_OPTIONS[0]: ${JSON.stringify(selectedPublicacaoAtiva || publicacao_ativa_OPTIONS[0])}`)
      // console.log(`selectedPublicacaoAutorNaoIny || publicacao_autor_naoiny_OPTIONS[0]: ${JSON.stringify(selectedPublicacaoAutorNaoIny || publicacao_autor_naoiny_OPTIONS[0])}\n`)

      setFile(null);
      setSelectedPublicacaoTipo(null);
      setSelectedPublicacaoAno(null);
      setSelectedPublicacaoAreaConhecim(null);
      setSelectedPublicacaoAutorNaoIny(null);
      setSelectedPublicacaoAtiva(null);

      book.arquivo_nome.value = "";
      book.autor_sobrenome.value = "";
      book.autor_nome.value = "";
      book.publicacao_ano.value = "";
      book.publicacao_titulo.value = "";
      book.publicacao_complemento.value = "";
      book.publicacao_tipo.value = "";
      book.publicacao_area_conhecim.value = "";
      book.publicacao_autor_naoiny.value = "";
      book.publicacao_url_pdf.value = "";
      book.publicacao_ativa.value = "";

      setOnEdit(null);
      setFile(null)
      getBooks();
    } catch (error) {
      console.error('Erro ao enviar formulário:', error);
    }
    // console.log(JSON.stringify(FormData))
  }
  return (
    <>
      <form className={styles.formContainer} ref={ref} onSubmit={handleSubmit}>

        <div className={styles.inputArea} >
          <label className={styles.label} htmlFor="arquivo_nome">Código da publicação</label>
          <input className={styles.input} name="arquivo_nome" id="arquivo_nome" required />
        </div>
        <div className={styles.inputArea} >
          <label className={styles.label} htmlFor="publicacao_tipo">Tipo de publicação</label>
          <Select
            className={styles.inputDropdown}
            name="publicacao_tipo"
            id="publicacao_tipo"
            placeholder="Selecione"
            options={publicacao_tipo_OPTIONS}
            value={selectedPublicacaoTipo}
            onChange={option => setSelectedPublicacaoTipo(option)}
            required
          />
        </div>
        <div className={styles.inputArea} >
          <label className={styles.label} htmlFor="autor_sobrenome">Sobrenome do autor</label>
          <input className={styles.input} name="autor_sobrenome" id="autor_sobrenome" required />
        </div>
        <div className={styles.inputArea} >
          <label className={styles.label} htmlFor="autor_nome">Nome do autor</label>
          <input className={styles.input} name="autor_nome" id="autor_nome" required />
        </div>
        <div className={styles.inputArea} >
          <label className={styles.label} htmlFor="publicacao_ano">Ano de publicação</label>
          <input
            style={{ paddingLeft: "5px" }}
            className={styles.inputDropdown}
            name="publicacao_ano"
            id="publicacao_ano"
            placeholder="Selecione"
            // options={getYears()}
            value={selectedPublicacaoAno} // Definindo o valor selecionado
            // onChange={option => setSelectedPublicacaoAno(option)}
            required
          />
        </div>
        <div className={styles.inputArea} >
          <label className={styles.label} htmlFor="publicacao_titulo">Título da publicação</label>
          <input className={styles.input} name="publicacao_titulo" id="publicacao_titulo" required />
        </div>
        <div className={styles.inputArea} >
          <label className={styles.label} htmlFor="publicacao_complemento">Complemento</label>
          <input className={styles.input} name="publicacao_complemento" id="publicacao_complemento" required />
        </div>
        <div className={styles.inputArea} >
          <label className={styles.label} htmlFor="publicacao_area_conhecim">Área do conhecimento</label>
          <Select className={styles.inputDropdown} name="publicacao_area_conhecim" id="publicacao_area_conhecim" placeholder="Selecione" options={publicacao_area_conhecim_OPTIONS} value={selectedPublicacaoAreaConhecim} onChange={option => setSelectedPublicacaoAreaConhecim(option)} required />
        </div>
        <div className={styles.inputArea} >
          <label className={styles.label} htmlFor="publicacao_autor_naoiny"> É autor indígena?</label>
          <Select
            className={styles.inputDropdown}
            name="publicacao_autor_naoiny"
            id="publicacao_autor_naoiny"
            placeholder="Selecione"
            options={publicacao_autor_naoiny_OPTIONS}
            value={selectedPublicacaoAutorNaoIny || publicacao_autor_naoiny_OPTIONS[1]} // Assume que o primeiro item é "Sim" com valor '1'
            onChange={option => setSelectedPublicacaoAutorNaoIny(option)}
            required
          />
        </div>
        <div className={styles.inputArea} >
          <label className={styles.label} htmlFor="publicacao_ativa">Tornar esta publicação pública em Lista de Bibliotegrafia?</label>
          <Select className={styles.inputDropdown}
            name="publicacao_ativa"
            id="publicacao_ativa"
            placeholder="Selecione"
            options={publicacao_ativa_OPTIONS}
            value={selectedPublicacaoAtiva || publicacao_ativa_OPTIONS[0]}
            onChange={option => setSelectedPublicacaoAtiva(option)}
            required
          />
        </div>

        <hr className={styles.divider} />

        <div className={styles.alert}>
          <TbAlertCircleFilled className={styles.alertIcon} />
          <h4><strong>&nbsp;Atenção!</strong> Ao anexar um arquivo, o<strong>&nbsp;Link da publicação&nbsp;</strong>será substuído imediatamente.</h4>
        </div>

        <div className={styles.inputArea} >
          <label className={styles.label} htmlFor="file">Arquivo da publicação</label>
          <div id="file-dropzone" className={styles.dropzone}> Solte um arquivo aqui ou<strong>&nbsp;Clique aqui para escolher</strong></div>
          <span style={{ fontSize: '0.8rem', color: 'gray' }}>* Caso exista ou não uma URL abaixo, ao anexar o arquivo ela será substuída.</span>
        </div>
        <div className={styles.inputArea} >
          <label className={styles.label} htmlFor="publicacao_url_pdf">Link da publicação</label>
          <input className={styles.input} name="publicacao_url_pdf" id="publicacao_url_pdf" placeholder='' />
        </div>


        <button className={styles.button} type="submit">SALVAR</button>
      </form>
    </>
  );
};

export default FormBooks;
