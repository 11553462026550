// AcervoFotografico.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import styles from '../styles/AcervoFotografico.module.css';
import Footer from '../components/Footer';

import ImagemAnimal from "../assets/thumb_imagem_animal.jpg"
import ImagemHumana from "../assets/thumb_imagem_humana.jpg"
import ImagemCosmologica from "../assets/thumb_imagem_cosmologica.jpg"
import ImagemOutras from "../assets/thumb_imagem_outras.png"

const ImagemCategoria = ({ categoria }) => {
  let imagem;

  switch (categoria) {
    case 'Imagens animais':
      imagem = ImagemAnimal;
      break;
    case 'Imagens humanas':
      imagem = ImagemHumana;
      break;
    case 'Imagens cosmológicas':
      imagem = ImagemCosmologica;
      break;
    case 'Imagens de outras formas':
      imagem = ImagemOutras;
      break;
    // Adicione cases para outras categorias, se necessário
    default:
      imagem = null;
      break;
  }

  return <img src={imagem} alt={categoria} className={styles.imagemCategoria} />;
};


function AcervoFotografico() {
  const [categorias, setCategorias] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL_LINK}/active-photos`)
      .then(response => {
        const categoriasUnicas = [];
        response.data.forEach(categoria => {
          if (!categoriasUnicas.some(cat => cat.fotografia_categoria === categoria.fotografia_categoria)) {
            categoriasUnicas.push(categoria);
          }
        });
        setCategorias(categoriasUnicas);
      })
      .catch(error => {
        console.error('Erro ao buscar dados de fotografia: ', error);
      });
  }, []);

  return (
    <div className={styles.body}>

      <div className={styles.bodyContent}>

        <p className={styles.textWelcome}>SEJA BEM VINDO(A) AO Ritxoko – Acervo fotográfico</p>

        <p className={styles.paragraph}>
          As ceramistas inỹ são famosas sobretudo pela produção das ritxoko (na fala feminina; ritxoo; na fala masculina), bonecas de barro assado, pintadas e muitas vezes ornamentadas, que representam uma gama variada de imagens do mundo karajá. Presentes em diversos acervos e museus no Brasil e no exterior, Ofício e os Modos de Fazer as Bonecas Karajá foram reconhecidos pelo IPHAN como Patrimônio Cultural do Brasil em 2012. Neste acervo fotográfico, as ritxoko foram divididas em três categorias:
        </p>

        <p className={styles.paragraph}>
          1.  Imagens humanas, que figuram pessoas inỹ, como pura forma ou em cenas cotidianas.
          2. Imagens cosmológicas, que figuram seres não-humanos que habitam o cosmos inỹ, assim como cenas da mitologia.
          3. Imagens animais, que figuram animais, como pura forma ou figurando seus hábitos.
          4. Outras formas. Ainda que não sejam propriamente, ritxoko, bonecas, o acervo inclui também o registro fotográfico de peças cerâmicas diversos, que figuram objetos ou que são réplicas, em escala reduzida, de utensílios que no passado se usava no cotidiano, como panelas e potes.
        </p>

        <p className={`${styles.paragraph}`} style={{ marginBottom: "0" }}>
          Este acervo fotográfico é uma documentação digital de acervos pessoais constituídos de peças adquiridas junto aos Karajá, que visa manter um registro das peças, de modo a contribuir para a valorização da produção cerâmica karajá, assim como constituir uma fonte de pesquisa aberta. O propósito da inscrição deste acervo na Biblioteca karajádigital é mantê-lo aberto, permitindo ampliá-lo ao longo do tempo e a partir da colaboração entre diferentes atores.
        </p>

        <div className={styles.lineSeparate}></div>

        <div className={styles.grid}>
          {categorias
            // Ordena as categorias, colocando "Outras formas de imagem" por último
            .sort((a, b) => {
              if (a.fotografia_categoria === 'Imagens de outras formas') return 1;
              if (b.fotografia_categoria === 'Imagens de outras formas') return -1;
              return 0;
            })
            // Mapeia as categorias ordenadas
            .map((categoria) => (
              <div className={styles.imagem} key={categoria.fotografia_categoria}>
                <Link to={`/acervo-fotografico/${categoria.fotografia_categoria}`}>
                  <ImagemCategoria categoria={categoria.fotografia_categoria} />
                </Link>
              </div>
            ))}
        </div>


        <div className={styles.lineSeparate}></div>

        <Footer />
      </div>
    </div>
  );
}

export default AcervoFotografico;
