import styles from './FormUsers.module.css';
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

const FormUsers = ({ getUsers, onEdit, setOnEdit, token }) => {
    const ref = useRef();
    const [selectValues, setSelectValues] = useState({
        ativo: '',
        administrador: '',
        listar_publicacao: '',
        criar_publicacao: '',
        alterar_publicacao: '',
        deletar_publicacao: '',
        listar_fotografia: '',
        criar_fotografia: '',
        alterar_fotografia: '',
        deletar_fotografia: '',
    });

    useEffect(() => {
        if (onEdit) {
            const user = ref.current;

            user.nome.value = onEdit.nome;
            user.usuario.value = onEdit.usuario;
            user.senha.value = onEdit.senha;
            user.email.value = onEdit.email;

            setSelectValues({
                ativo: String(onEdit.ativo),
                administrador: String(onEdit.administrador),
                listar_publicacao: String(onEdit.listar_publicacao),
                criar_publicacao: String(onEdit.criar_publicacao),
                alterar_publicacao: String(onEdit.alterar_publicacao),
                deletar_publicacao: String(onEdit.deletar_publicacao),
                listar_fotografia: String(onEdit.listar_fotografia),
                criar_fotografia: String(onEdit.criar_fotografia),
                alterar_fotografia: String(onEdit.alterar_fotografia),
                deletar_fotografia: String(onEdit.deletar_fotografia),
            });
        }
    }, [onEdit]);

    const handleRadioChange = (field, value) => {
        setSelectValues(prev => ({ ...prev, [field]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const user = ref.current;

        if (
            !user.nome.value ||
            !user.usuario.value ||
            !user.senha.value ||
            !user.email.value ||
            !selectValues.ativo ||
            !selectValues.administrador
        ) {
            return toast.warn("Preencha todos os campos!");
        }

        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            };

            const userData = {
                nome: user.nome.value,
                usuario: user.usuario.value,
                senha: user.senha.value,
                email: user.email.value,
                ativo: selectValues.ativo,
                administrador: selectValues.administrador,
                listar_publicacao: selectValues.listar_publicacao,
                criar_publicacao: selectValues.criar_publicacao,
                alterar_publicacao: selectValues.alterar_publicacao,
                deletar_publicacao: selectValues.deletar_publicacao,
                listar_fotografia: selectValues.listar_fotografia,
                criar_fotografia: selectValues.criar_fotografia,
                alterar_fotografia: selectValues.alterar_fotografia,
                deletar_fotografia: selectValues.deletar_fotografia,
            };

            if (onEdit) {
                const response = await axios.put(`${process.env.REACT_APP_API_URL_LINK}/users/${onEdit.matricula}`, userData, config);
                toast.success(response.data);
            } else {
                const response = await axios.post(`${process.env.REACT_APP_API_URL_LINK}/users/`, userData, config);
                toast.success(response.data);
            }

            getUsers();
        } catch (error) {
            if (error.response && error.response.status === 409) {
                toast.error(error.response.data);
            } else {
                toast.error("Ocorreu um erro ao salvar o usuário.");
                console.error(error);
            }
        }

        // Limpa os campos após o envio do formulário
        user.nome.value = "";
        user.usuario.value = "";
        user.senha.value = "";
        user.email.value = "";

        setSelectValues({
            ativo: '',
            administrador: '',
            listar_publicacao: '',
            criar_publicacao: '',
            alterar_publicacao: '',
            deletar_publicacao: '',
            listar_fotografia: '',
            criar_fotografia: '',
            alterar_fotografia: '',
            deletar_fotografia: '',
        });

        // Reseta o estado de edição
        setOnEdit(null);
    };

    const renderRadioGroup = (name, label) => {
        const isSpecialField = name === 'administrador' || name === 'ativo';
        const containerClass = isSpecialField ? `${styles.inputAreaPermissions} ${styles.specialField}` : styles.inputAreaPermissions;
    
        return (
            <div className={containerClass}>
                <label className={styles.labelPermissions}>{label}</label>
                <div>
                    <label style={{marginRight: '20px'}}>
                        <input
                            type="radio"
                            name={name}
                            value="1"
                            checked={selectValues[name] === '1'}
                            onChange={() => handleRadioChange(name, '1')}
                        />
                        Sim
                    </label>
                    <label>
                        <input
                            type="radio"
                            name={name}
                            value="0"
                            checked={selectValues[name] === '0'}
                            onChange={() => handleRadioChange(name, '0')}
                        />
                        Não
                    </label>
                </div>
            </div>
        );
    };
    
    return (
        <form className={styles.formContainer} ref={ref} onSubmit={handleSubmit}>
            <h3 style={{ alignSelf: "start", marginTop: "10px", marginBottom: "10px", fontWeight: 'bold' }}>Dados pessoais do usuário</h3>
            <div className={styles.inputArea}>
                <label className={styles.label} htmlFor="nome">Nome</label>
                <input className={styles.input} name="nome" id="nome" />
            </div>
            <div className={styles.inputArea}>
                <label className={styles.label} htmlFor="usuario">Usuário</label>
                <input className={styles.input} style={{ textTransform: 'uppercase' }} name="usuario" id="usuario" />
            </div>
            <div className={styles.inputArea}>
                <label className={styles.label} htmlFor="senha">Senha</label>
                <input className={styles.input} style={{ textTransform: 'none' }} name="senha" id="senha" />
            </div>
            <div className={styles.inputArea}>
                <label className={styles.label} htmlFor="email">E-mail</label>
                <input className={styles.input} style={{ textTransform: 'none' }} name="email" type="email" id="email" />
            </div>
            <h3 style={{ alignSelf: "start", marginTop: "30px", marginBottom: "10px", fontWeight: 'bold' }}>Permissões do usuário</h3>
            <div className={styles.formPermissions}>
                {renderRadioGroup('ativo', 'Ativo')}
                {renderRadioGroup('administrador', 'Administrador')}
                {renderRadioGroup('listar_publicacao', 'Listar publicação')}
                {renderRadioGroup('criar_publicacao', 'Criar publicação')}
                {renderRadioGroup('alterar_publicacao', 'Alterar publicação')}
                {renderRadioGroup('deletar_publicacao', 'Deletar publicação')}
                {renderRadioGroup('listar_fotografia', 'Listar fotografia')}
                {renderRadioGroup('criar_fotografia', 'Criar fotografia')}
                {renderRadioGroup('alterar_fotografia', 'Alterar fotografia')}
                {renderRadioGroup('deletar_fotografia', 'Deletar fotografia')}
            </div>
            <button className={styles.button} type="submit">SALVAR</button>
        </form>
    );

}
    
    export default FormUsers;
    