import React, { useMemo, useState, useEffect } from "react";
import axios from "axios";
import { useTable, useFilters, useGlobalFilter, useSortBy } from "react-table";
import { FaTrash, FaEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import styles from './GridBooks.module.css';
import { BsArrowDownSquareFill, BsArrowUpSquareFill } from "react-icons/bs";
import { MdEditSquare } from "react-icons/md";
import { RiAddBoxFill } from "react-icons/ri";
import FormModal from "./FormModal.js";
import FormBooks from "./FormBooks.js";
import RegisterBar from "./RegisterBar.js";



const GridBooks = ({ books, getBooks, setBooks, setOnEdit, onEditClick, token }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleEdit = (item) => {
        setOnEdit(item);
        onEditClick(); // Chama a função para abrir o modal
    };

    const handleDelete = async (id) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}` // Adiciona o token ao cabeçalho da solicitação
                }
            };

            const resposta = await axios.delete(`${process.env.REACT_APP_API_URL_LINK}/books/${id}`, config);
            const newArray = books.filter((user) => user.id !== id);
            setBooks(newArray);
            toast.success(resposta.data.result);
            if (resposta.data.message) {
                toast.warn(resposta.data.message);
            }
        } catch (error) {
            toast.error(error.response.data);
        }

        setOnEdit(null);

        getBooks();
    };


    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleEditModalOpen = () => {
        setIsModalOpen(true); // Função para abrir o modal
    }

    useEffect(() => {
        getBooks();
    }, []);

    const data = useMemo(() => books, [books]);

    const columns = useMemo(
        () => [
            {
                Header: "Título",
                accessor: "publicacao_titulo",
                sortType: "alphanumeric",
            },
            {
                Header: "Autor",
                accessor: "arquivo_nome",
                sortType: "alphanumeric",
            },
            {
                Header: "Sobrenome",
                accessor: "autor_sobrenome",
                sortType: "alphanumeric",
            },
            {
                Header: "Publicado em",
                accessor: "publicacao_ano",
                sortType: "basic",
            },
            {
                Header: "Área",
                accessor: "publicacao_area_conhecim",
                sortType: "basic",
            },
            {
                Header: "Tipo de publicação",
                accessor: "publicacao_tipo",
                sortType: "basic",
            },
            {
                Header: "Link URL",
                accessor: "publicacao_url_pdf",
                sortType: "basic",
            },
            {
                Header: "Status",
                id: "status",
                accessor: "publicacao_ativa",
                sortType: "basic",
                Cell: ({ value }) => (
                    <div className={value === 1 ? styles.buttonActive : styles.buttonInactive}>
                        {value === 1 ? "Ativa" : "Pendente"}
                    </div>
                ),

            },
            {
                Header: "Editar",
                id: "edit",
                Cell: ({ row }) => (
                    <div className={styles.buttonEdit} onClick={() => handleEdit(row.original)}>
                        <MdEditSquare />
                        <p style={{ fontSize: "0.875rem" }}>Editar</p>
                    </div>
                ),

            },
            {
                Header: "Apagar",
                id: "delete",
                Cell: ({ row }) => (
                    <div className={styles.buttonTrash} onClick={() => handleDelete(row.original.id)}>
                        <FaTrash />
                        <p style={{ fontSize: "0.875rem" }}>Apagar</p>
                    </div>
                ),
            },
        ],
        []
    );

    const centeredColumns = ["edit", "delete", "status"]; // Array de IDs de colunas para centralizar

    const isCenteredColumn = (columnId) => centeredColumns.includes(columnId);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state,
        setGlobalFilter,
    } = useTable({ columns, data }, useFilters, useGlobalFilter, useSortBy);

    const { globalFilter } = state;

    return (
        <div className={styles.body}>
            <div className={styles.headerActions}>
                <h3 style={{fontWeight: 'bold'}}>PUBLICAÇÕES CADASTRADAS</h3>
                {/* <RegisterBar/> */}
                <div className={styles.headerTitle}>
                    <input
                        className={styles.inputSearch}
                        value={globalFilter || ""}
                        onChange={(e) => setGlobalFilter(e.target.value)}
                        placeholder="Pesquisar em todos os campos"
                    />
                    <RiAddBoxFill className={styles.iconCreate} onClick={handleOpenModal} />
                </div>
            </div>

            <FormModal isOpen={isModalOpen} onClose={handleCloseModal} token={token} >
                <FormBooks getBooks={getBooks} token={token} />
            </FormModal>

            <div>
                {/* <h2>Lista de publicações</h2> */}
            </div>

            <table className={styles.table} {...getTableProps()}>
                <thead className={styles.thread}>
                    {headerGroups.map((headerGroup) => (
                        <tr className={styles.trHead} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th className={`${styles.th} ${isCenteredColumn(column.id) ? `${styles.tdCenter} ${styles.buttonsWidth}` : ""}`} {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    <span>
                                        {column.isSorted ? (
                                            column.isSortedDesc ? (
                                                <BsArrowDownSquareFill style={{ marginRight: "2px" }} />
                                            ) : (
                                                <BsArrowUpSquareFill style={{ marginRight: "2px" }} />
                                            )
                                        ) : (
                                            ""
                                        )}
                                    </span>
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody className={styles.tbody} {...getTableBodyProps()}>
                    <Tooltip style={{ zIndex: 10 }} id='my-tooltip-cell-value' />

                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <tr className={styles.tr} {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                    <td className={`${styles.td} ${cell.column.id === "edit" || cell.column.id === "delete" || cell.column.id === "status" ? `${styles.tdCenter} ${styles.buttonsWidth}` : ""}`}
                                        data-tooltip-content={cell.value}
                                        data-tooltip-id="my-tooltip-cell-value"
                                        data-tooltip-place="top"
                                        data-tooltip-delay-hide={150}
                                        data-tooltip-delay-show={150}
                                        {...cell.getCellProps()}
                                    >
                                        {cell.render("Cell")}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default GridBooks;
