// PhotoCard.js

import React from 'react';
import styles from "./PhotoCard.module.css"

function PhotoCard({ foto }) {
  return (
    <div className={styles.bodyPhotoCard}>
      {/* <img style={{width: "100px"}} src={foto.fotografia_url_image} alt={foto.fotografia_titulo} /> */}
      <img  src={foto.fotografia_url_image} alt={foto.fotografia_titulo} />
      {/* <h3>{foto.fotografia_titulo}</h3> */}
      {/* <p>{foto.fotografia_descricao}</p> */}
    </div>
  );
}

export default PhotoCard;
