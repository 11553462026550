import React, { useState, useEffect } from 'react';
import Footer from "../components/Footer.js";
import NavBar from "../components/Navbar.js";
import GridBooks from "../components/GridBooks.js";
import FormBooks from "../components/FormBooks.js";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import RegisterBar from "../components/RegisterBar.js";
import styles from './../styles/CadastrarLivro.module.css'
import FormModal from "../components/FormModal.js";
import { RiAddBoxFill } from "react-icons/ri";

export default function CadastrarLivro({ token }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [books, setBooks] = useState([]);
  const [onEdit, setOnEdit] = useState(null);

  const getBooks = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}` // Adiciona o token ao cabeçalho da solicitação
        }
      }
      const res = await axios.get(`${process.env.REACT_APP_API_URL_LINK}/all-books`, config);
      setBooks(res.data.sort((a, b) => (a.nome > b.nome ? 1 : -1)));
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setOnEdit(null);
  };

  const handleEditModalOpen = () => {
    setIsModalOpen(true); // Função para abrir o modal
  }

  useEffect(() => {
    getBooks();
  }, []);

  return (
    <div className={styles.body}>
      {/* <NavBar /> */}
      <RegisterBar />

      {/* <div className={styles.headerGridCard}> */}
        {/* <h3>Lista de publicacões cadastradas</h3> */}
        {/* <button className={styles.buttonRegister} onClick={handleOpenModal}>Novo cadastro</button> */}
        {/* <RiAddBoxFill style={{width: "50px", height: "50px"}} onClick={handleOpenModal}/> */}

        
      {/* </div> */}

      <GridBooks onEditClick={handleEditModalOpen} getBooks={getBooks} books={books} setBooks={setBooks} setOnEdit={setOnEdit} token={token} />

      <FormModal isOpen={isModalOpen} onClose={handleCloseModal} token={token} >
        <FormBooks onEdit={onEdit} setOnEdit={setOnEdit} getBooks={getBooks} token={token} />
      </FormModal>

      <Footer />
    </div>
  );
}
