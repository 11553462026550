import React from "react";
import axios from "axios";
import styles from './GridUsers.module.css';
import { FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import { MdEditSquare } from "react-icons/md";

const GridUsers = ({ users, setUsers, setOnEdit, onEditClick, token }) => {

    const handleEdit = (item) => {
        setOnEdit(item);
        onEditClick();
    }

    const handleDelete = async (matricula) => {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${token}` // Adiciona o token ao cabeçalho da solicitação
                }
            };

            await axios.delete(`${process.env.REACT_APP_API_URL_LINK}/users/${matricula}`, config);
            const newArray = users.filter((user) => user.matricula !== matricula);
            setUsers(newArray);
            toast.success("Usuário deletado com sucesso!");
        } catch (error) {
            if (error.response) {
                toast.error(error.response.data);
                console.error(error);
            } else {
                console.error(error);
            }
        }
        setOnEdit(null);
    }

    return (
        <div className={styles.table}>
            <div className={styles.thread}>
                <div className={`${styles.tr} ${styles.trHeader}`}>
                    <div className={`${styles.th} ${styles['width-A']}`}>Nome</div>
                    <div className={`${styles.th} ${styles['width-B']}`}>Usuário</div>
                    <div className={`${styles.th} ${styles['width-C']}`}>Email</div>
                    <div className={`${styles.th} ${styles['width-D']}`}>Senha</div>
                    <div className={`${styles.th} ${styles['width-E']}`}>Admin</div>
                    <div className={`${styles.th} ${styles['width-E']}`}>Ativo</div>
                    <div className={`${styles.th} ${styles['width-Z']}`}>Editar</div>
                    <div className={`${styles.th} ${styles['width-Z']}`}>Apagar</div>
                </div>
            </div>
            <div className={styles.tbody}>
                {users.map((item, i) => (
                    <div key={i} className={styles.tr}>
                        <div className={`${styles.td} ${styles['width-A']}`}>{item.nome}</div>
                        <div className={`${styles.td} ${styles['width-B']}`}>{item.usuario}</div>
                        <div className={`${styles.td} ${styles['width-C']}`}>{item.email}</div>
                        <div className={`${styles.td} ${styles['width-D']}`}>{item.senha}</div>
                        <div className={`${styles.td} ${styles['width-E']}`}>{(item.administrador === 1 ? 'Sim' : 'Não')}</div>
                        <div className={`${styles.td} ${styles['width-E']}`}>{(item.ativo === 1 ? 'Sim' : 'Não')}</div>
                        
                        <div onClick={() => handleEdit(item)} className={`${styles.td} ${styles.buttonEdit} ${styles['width-Z']}`}>
                            <MdEditSquare />
                            <p>Editar</p>
                        </div>
                        <div onClick={() => handleDelete(item.matricula)} className={`${styles.td} ${styles.buttonTrash} ${styles['width-Z']}`}>
                            <FaTrash />
                            <p>Apagar</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default GridUsers;
