import React, { createContext, useContext, useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import Cookies from 'js-cookie';

// Criar o contexto
const AuthContext = createContext();

// Componente de provedor do contexto
export const AuthProvider = ({ children }) => {

  const [token, setToken] = useState(null);
  const [logged, setLogged] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTokenFromServer = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL_LINK}/get-token`, {
          withCredentials: true
        });
        console.log(JSON.stringify(response))
        const { token } = response.data;
        setToken(token);
        setLogged(true);
      } catch (error) {
        console.error('Erro ao recuperar o token:', error);
      } finally {
        // Código executado depois do try ou catch, independente de erros
        setLoading(false); // Desativar o indicador de carregamento
      }
    };

    
    console.log("🧐🧐 [token] retornado de getToken (Auth)...");

    // if (token) {

    //   console.log("Token retornado por getToken (Auth):", token);

    //   setToken(token);
    //   setLogged(true);

    //   return; // Retornar para evitar a chamada para fetchTokenFromServer
    // } else {
      //   console.log("Token não encontrado em getToken ❌(Auth).");
    // }
    
    fetchTokenFromServer(); // Chamar apenas se não houver token válido no cookie
  }, []);
  
  if (loading) {
    return <div>Carregando...</div>; // Ou algum componente de loading
  }
  return (
    <AuthContext.Provider value={{ logged, setLogged, token, setToken }}>
      {children}
    </AuthContext.Provider>
  );
};

// Hook personalizado para usar o contexto de autenticação
export const useAuth = () => useContext(AuthContext);
