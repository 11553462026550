import styles from "./../styles/Login.module.css"
import { FaUser, FaLock } from "react-icons/fa";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from 'react-toastify'
import { useAuth } from '../contexts/AuthContext';
import Footer  from '../components/Footer'


export default function Login() {

    const { logged, setLogged } = useAuth();
    const { token, setToken }  = useAuth();

    const [usuario, setUsuario] = useState("");
    const [senha, setSenha] = useState("");

    const navigate = useNavigate();
  
    useEffect(() => {
      if (logged) {
        navigate('/area-do-colaborador');
      }
    }, [logged, navigate]);

    const handleLogin = async () => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL_LINK}/login`,
                {
                    usuario,
                    senha
                },
                {
                    withCredentials: true // Permitir envio de cookies
                }
            );

            const { data } = response;


            if (data.token) {
                // console.log(`data.token primeiro "if" após o axios post: ${data.token}`)
                setToken(data.token); 
            }
            // console.log(data)
            setLogged(true)
            // navigate('/area-do-colaborador');
            toast.success(data.message)
        } catch (error) {
            if (error.response && error.response.status === 401) {
                toast.error(error.response.data)
            } else {
                toast.error("Não é possível fazer login no momento. Por favor, tente novamente mais tarde.")
                console.error(error);
            }
        }
    };

    return (
        <div className={styles.body}>
            <div className={styles.bodyContainer}>
                <div className={styles.ContainerArea}>
                    <h4 className={styles.WelcomeText}> ACESSO RESTRITO </h4>
                    <h4 className={styles.InformationText}>Informe suas credenciais abaixo para acessar o painel administrativo</h4>
                    <div className={styles.ContainerLogin}>
                        <h4 className={styles.h4}>Usuário</h4>
                        <div className={styles.ContainerInput}>
                            <FaUser className={styles.inputIcon} />
                            <input
                                className={styles.InputLogin}
                                type="text" name="usuario"
                                placeholder="Insira seu usuário"
                                value={usuario}
                                onChange={(e) => setUsuario(e.target.value)}
                            />
                        </div>
                        <h4 className={styles.h4}>Senha</h4>
                        <div className={styles.ContainerInput}>
                            <FaLock className={styles.inputIcon} />
                            <input
                                className={styles.InputLogin}
                                style={{ textTransform: "none" }}
                                type="password"
                                name="senha"
                                placeholder="Insira sua senha"
                                value={senha}
                                onChange={(e) => setSenha(e.target.value)}
                            />
                        </div>
                        <button className={styles.ButtonLogin} onClick={handleLogin}>
                            <h3>ENTRAR</h3>
                        </button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}