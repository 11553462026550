import { FaInstagram, FaTwitter } from "react-icons/fa";
import { PiPhoneFill } from "react-icons/pi";
import { GrFacebookOption } from "react-icons/gr";
import { MdEmail } from "react-icons/md";
import styles from './Footer.module.css'
import FooterImage from '../assets/footer_image_karaja.png'

export default function Footer() {
    return (
        <div className={styles.FooterBackground}>
            {/* <div className={styles.ContainerCircleIcons}>
                <div className={styles.CircleSocialIcon}>
                    <GrFacebookOption className={styles.SocialIcon} />
                </div>
                <div className={styles.CircleSocialIcon}>
                    <FaInstagram className={styles.SocialIcon} />
                </div>
                <div className={styles.CircleSocialIcon}>
                    <FaTwitter className={styles.SocialIcon} />
                </div>
            </div> */}
            {/* <p className={styles.FooterDescription}>Projeto colaborativo desenvolvido pelo curso de antropologia UFOPA - Santarém - Pará</p> */}
            <img className={styles.FooterImage} src={FooterImage} />
            <div className={styles.FooterContactsBackground}>
                {/* <div className={styles.FooterContactPhone}>
                    <PiPhoneFill className={styles.FooterContactIcon} />
                    <a style={{ color: "inherit", textDecoration: "none", margin: 0, justifyContent: 'center' }} rel="noopener" target="_blank" href="https://wa.me/93991639692?text=Olá! Gostaria de falar sobre o Karaja Digital!" className={styles.FooterContactsText}>+55 (93) 99163-9692</a>
                </div> */}
                <div className={styles.FooterContactEmail}>
                    <MdEmail className={styles.FooterContactIcon} />
                    <a style={{ color: "inherit", textDecoration: "none", margin: 0, justifyContent: 'center' }} rel="noopener" href="mailto:karajadigital@gmail.com" className={styles.FooterContactsText}>karajadigital@gmail.com</a>
                </div>
            </div>
        </div>
    )
}