// App.js

import React, { useState } from 'react';
import MeuComponente from '../components/MeuComponente';
import { AuthProvider, useAuth } from '../contexts/AuthContext';
import NavBar from '../components/Navbar';

function Testes() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
        <NavBar />
    </div>
  );
}

export default Testes;
