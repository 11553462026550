import styles from './FormPhotos.module.css';
import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import Dropzone from 'dropzone';
import 'dropzone/dist/dropzone.css';
import { TbAlertCircleFilled } from 'react-icons/tb';
import Select from "react-select"
import CreatableSelect from "react-select/creatable"

const FormPhotos = ({ getPhotos, onEdit, setOnEdit, token }) => {
  const [file, setFile] = useState(null);

  const [selectedFotografiaCategoria, setSelectedFotografiaCategoria] = useState();
  const [selectedFotografiaAno, setSelectedFotografiaAno] = useState(null);
  const [selectedFotografiaAtiva, setSelectedFotografiaAtiva] = useState(null); //teste

  const ref = useRef();

  const yesNoOptions = [
    { value: '1', label: 'Sim' },
    { value: '0', label: 'Não' }
  ]

  const fotografia_categoria_OPTIONS = [
    { value: 'Imagens animais', label: 'Imagens animais' },
    { value: 'Imagens cosmológicas', label: 'Imagens cosmológicas' },
    { value: 'Imagens humanas', label: 'Imagens humanas' },
    { value: 'Imagens de outras formas', label: 'Imagens de outras formas' }
  ]

  const fotografia_ativa_OPTIONS = [
    { value: 1, label: 'Sim' },
    { value: 0, label: 'Não' },
  ];

  const getYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear; i >= currentYear - 200; i--) {
      years.push({ value: i, label: i });
    }
    return years;
  }



  useEffect(() => {
    if (onEdit) {
      const photo = ref.current;
      // console.log(token)
      const selectedCategoria = fotografia_categoria_OPTIONS.find(option => option.value === onEdit.fotografia_categoria);
      const selectedAtiva = fotografia_ativa_OPTIONS.find(option => option.value === onEdit.fotografia_ativa);
      const ano = getYears().find(option => option.value === onEdit.fotografia_ano);

      setSelectedFotografiaCategoria(selectedCategoria)
      setSelectedFotografiaAtiva(selectedAtiva);
      setSelectedFotografiaAno(ano);


      photo.arquivo_nome.value = onEdit.arquivo_nome;
      photo.fotografia_categoria.value = onEdit.fotografia_categoria;
      photo.fotografia_titulo.value = onEdit.fotografia_titulo;
      photo.fotografia_descricao.value = onEdit.fotografia_descricao;
      photo.fotografia_ano.value = onEdit.fotografia_ano;
      photo.fotografia_url_image.value = onEdit.fotografia_url_image;
      photo.fotografia_ativa.value = onEdit.fotografia_ativa;
      photo.aldeia_nome.value = onEdit.aldeia_nome;
      photo.artesa_nome.value = onEdit.artesa_nome;
    }
  }, [onEdit]);


  useEffect(() => {
    const dropzone = new Dropzone("#file-dropzone", {
      url: '/', // URL de upload, se necessário
      maxFiles: 1, // Número máximo de arquivos que podem ser enviados
      acceptedFiles: 'image/jpeg, image/png, image/jpg, image/gif', // Tipos de arquivos aceitos
      init: function () {
        this.on('addedfile', function (file) {
          // Callback chamado quando um arquivo é adicionado
          console.log('Added file:', file);
          setFile(file);
        });

        this.on('uploadprogress', function (file, progress, bytesSent) {
          // Atualiza a barra de progresso com o progresso atual
          console.log('Upload progress:', progress);
          // Atualize a barra de progresso aqui com o valor de progresss
        });

        this.on('totaluploadprogress', function (totalProgress) {
          // Atualiza a barra de progresso total com o progresso total
          console.log('Total upload progress:', totalProgress);
          // Atualize a barra de progresso total aqui com o valor de totalProgresss
        });
      }
    });

    // Adicione estilos ou classes CSS personalizadas aqui
    return () => {
      dropzone.destroy(); // Limpa o Dropzone quando o componente é desmontado
    };
  }, []);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);

  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const photo = ref.current;
  
    if (
      !photo.arquivo_nome.value ||
      !photo.fotografia_categoria.value ||
      !photo.fotografia_titulo.value ||
      !photo.fotografia_descricao.value ||
      !photo.fotografia_ano.value ||
      !photo.fotografia_ativa.value ||
      !photo.aldeia_nome.value ||
      !photo.artesa_nome.value
    ) {
      return toast.warn("Preencha todos os campos!");
    }
  
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
  
      const formData = new FormData();
      const values = {
        arquivo_nome: photo.arquivo_nome.value,
        fotografia_categoria: photo.fotografia_categoria.value,
        fotografia_titulo: photo.fotografia_titulo.value,
        fotografia_descricao: photo.fotografia_descricao.value,
        fotografia_ano: photo.fotografia_ano.value,
        fotografia_url_image: photo.fotografia_url_image.value,
        fotografia_ativa: photo.fotografia_ativa.value,
        aldeia_nome: photo.aldeia_nome.value,
        artesa_nome: photo.artesa_nome.value
      };
  
      Object.entries(values).forEach(([key, value]) => {
        formData.append(key, value);
      });
  
      if (file) {
        formData.append('file', file);
      }
  
      let response;
  
      if (onEdit) {
        await axios
          .put(`${process.env.REACT_APP_API_URL_LINK}/photos/${onEdit.id}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${token}`
            }
          }, config)
          .then(({ data }) => {
            toast.success(data)
            getPhotos();
          })
          .catch((error) => {
            toast.error(error.response.data);
            console.error(error);
          });
      } else {
        await axios
          .post(`${process.env.REACT_APP_API_URL_LINK}/photos`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${token}`
            }
          })
          .then(({ data }) => {
            toast.success(data)
            getPhotos();
          })
          .catch((error) => {
            if (error.response.status === 409) {
              toast.error(error.response.data);
            } else {
              toast.error(error.response.data);
              console.error(error);
            }
          });
      }

      
      setFile(null);
      setSelectedFotografiaCategoria(null);
      setSelectedFotografiaAno(null);
      setSelectedFotografiaAtiva(null);
  
      photo.arquivo_nome.value = "";
      photo.fotografia_categoria.value = "";
      photo.fotografia_titulo.value = "";
      photo.fotografia_descricao.value = "";
      photo.fotografia_ano.value = "";
      photo.fotografia_url_image.value = "";
      photo.fotografia_ativa.value = "";
      photo.aldeia_nome.value = "";
      photo.artesa_nome.value = "";
  
      setOnEdit(null);
      setFile(null);

      getPhotos();
    } catch (error) {
      console.error('Erro ao enviar formulário:', error);
    }
  };
  

  return (
    <>
      <form className={styles.formContainer} ref={ref} onSubmit={handleSubmit}>

        <div className={styles.inputArea} >
          <label className={styles.label} htmlFor="arquivo_nome">Qual deve ser o nome do arquivo?</label>
          <input className={styles.input}  name="arquivo_nome" id="arquivo_nome" required />
        </div>
        <div className={styles.inputArea} >
          <label className={styles.label} htmlFor="fotografia_categoria">Qual a categoria da fotografia?</label>
          <Select className={styles.inputDropdown}
            name="fotografia_categoria"
            id="fotografia_categoria"
            placeholder="Selecione"
            options={fotografia_categoria_OPTIONS}
            value={selectedFotografiaCategoria}
            onChange={option => setSelectedFotografiaCategoria(option)}
            required

          />
        </div>
        <div className={styles.inputArea} >
          <label className={styles.label} htmlFor="fotografia_titulo">Qual o título da fotografia?</label>
          <input className={styles.input} name="fotografia_titulo" id="fotografia_titulo" required />
        </div>
        <div className={styles.inputArea} >
          <label className={styles.label} htmlFor="fotografia_descricao">Qual a descrição da fotografia?</label>
          <input className={styles.input}  name="fotografia_descricao" id="fotografia_descricao" required />
        </div>
        <div className={styles.inputArea} >
          <label className={styles.label} htmlFor="fotografia_ano">Qual ano da fotografia?</label>
          <input
            className={styles.input}
            // className={styles.inputDropdown}
            name="fotografia_ano"
            id="fotografia_ano"
            placeholder="Selecione"
            // options={getYears()}
            value={selectedFotografiaAno} // Definindo o valor selecionado
            // onChange={option => setSelectedFotografiaAno(option)}
            required
          />
        </div>
        {/* <div className={styles.inputArea} >
          <label className={styles.label} htmlFor="fotografia_url_image">Qual a URL da fotografia?</label>
          <input className={styles.input} name="fotografia_url_image" id="fotografia_url_image" required />
        </div> */}
        <div className={styles.inputArea} >
          <label className={styles.label} htmlFor="aldeia_nome">Qual a aldeia de origem da fotografia?</label>
          <input className={styles.input} name="aldeia_nome" id="aldeia_nome" required />
        </div>
        <div className={styles.inputArea} >
          <label className={styles.label} htmlFor="artesa_nome">Qual o nome da artesã?</label>
          <input className={styles.input}  name="artesa_nome" id="artesa_nome" required />
        </div>
        <div className={styles.inputArea} >
          <label className={styles.label} htmlFor="fotografia_ativa">Tornar esta fotografia púbica em Acervo Fotográfico?</label>
          <Select
            className={styles.inputDropdown}
            name="fotografia_ativa"
            placeholder="Selecione"
            options={fotografia_ativa_OPTIONS}
            value={selectedFotografiaAtiva || fotografia_ativa_OPTIONS[0]}
            onChange={option => setSelectedFotografiaAtiva(option)}
            required
          />
        </div>


        <hr className={styles.divider} />

        <div className={styles.alert}>
          <TbAlertCircleFilled className={styles.alertIcon} />
          <h4><strong>&nbsp;Atenção!</strong> Ao anexar um arquivo, o<strong>&nbsp;Link da fotografia&nbsp;</strong>será substuído imediatamente.</h4>
        </div>

        <div className={styles.inputArea} >
          <label className={styles.label} htmlFor="file">Arquivo da fotografia</label>
          <div id="file-dropzone" className={styles.dropzone}> Solte um arquivo aqui ou<strong>&nbsp;Clique aqui para escolher</strong></div>
          <span style={{ fontSize: '0.8rem', color: 'gray' }}>* Caso exista ou não uma URL abaixo, ao anexar o arquivo ela será substuída.</span>
        </div>
        <div className={styles.inputArea} >
          <label className={styles.label} htmlFor="fotografia_url_image">Link da fotografia</label>
          <input className={styles.input} name="fotografia_url_image" id="fotografia_url_image" placeholder='' />
        </div>


        <button className={styles.button} type="submit">SALVAR</button>
      </form>
    </>
  );
};

export default FormPhotos;
