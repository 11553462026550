import Footer from "../components/Footer";
import NavBar from "../components/Navbar";
import GridUsers from "../components/GridUsers.js";
import FormUsers from "../components/FormUsers.js";
import FormModal from "../components/FormModal.js";
import "react-toastify/dist/ReactToastify.css";
import { RiAddBoxFill } from "react-icons/ri";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import RegisterBar from "../components/RegisterBar.js";
import styles2 from './../styles/CadastrarLivro.module.css'

export default function CadastrarUsuario({ token }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [users, setUsers] = useState([]);
  const [onEdit, setOnEdit] = useState(null);

  const getUsers = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}` // Adiciona o token ao cabeçalho da solicitação
        }
      }
      const res = await axios.get(`${process.env.REACT_APP_API_URL_LINK}/users`, config);
      setUsers(res.data.sort((a, b) => (a.nome > b.nome ? 1 : -1)));
    } catch (error) {
      toast.error(error);
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setOnEdit(null);
  };

  const handleEditModalOpen = () => {
    setIsModalOpen(true); // Função para abrir o modal
  }

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className={styles2.body}>
      {/* <NavBar /> */}
      <RegisterBar />
      <div className={styles2.headerGridCard}>

        <h3 style={{fontWeight: 'bold'}}>USUÁRIOS CADASTRADOS</h3>
        <RiAddBoxFill className={styles2.iconCreate} onClick={handleOpenModal}/>
        {/* <button className={styles2.buttonRegister} onClick={handleOpenModal}>Cadastrar usuário</button> */}
      </div>


      <FormModal isOpen={isModalOpen} onClose={handleCloseModal} token={token} >
        <FormUsers onEdit={onEdit} setOnEdit={setOnEdit} getUsers={getUsers} token={token} />
      </FormModal>

      <GridUsers onEditClick={handleEditModalOpen} users={users} setUsers={setUsers} setOnEdit={setOnEdit} token={token} />
      <Footer />
    </div>
  )
}