import NavBar from "../components/Navbar";
import bannerHome from '../assets/indigenas karajas 1.jpg'
import styles from '../styles/Home.module.css'
import Footer from './../components/Footer.js'
import React, { useState } from 'react';
import LoginFormModal from './LoginFormModal';
import Testes from "./Testes.js";
import PessoaImage from "../assets/pessoa_image_home.png"
import CasaImage from "../assets/telhado_image_home.png"

export default function Home() {

    return (
        <div className={styles.body}>

            <div className={styles.ContainerBody}>
                <p className={styles.paragraph}>
                    A Biblioteca karajádigital é uma base de dados sobre os Inỹ: Karajá, Javaé e Ixỹbiòwa, três povos falantes de uma mesma língua macro-jê, o inỹrybè. Habitantes imemoriais da calha do rio Araguaia, suas aldeias se distribuem entre estados de Goiás, Mato Grosso, Tocantins e Pará. A maior parte delas, entretanto, se localiza na Ilha do Bananal (TO), centro do território tradicionalmente ocupado por esses três povos.
                </p>

                <p className={styles.paragraph}>
                    Este espaço foi originalmente criado em 2013, com a proposta de apresentar uma listagem da bibliografia produzida sobre estes povos e disponibilizar os arquivos em formato digital. Além dos documentos já existentes, o intuito era digitalizar e disponibilizar obras que existiam apenas em formato físico.
                </p>

                <p className={styles.paragraph}>
                    Com o passar do tempo, o projeto se expandiu e ganhou uma nova cara. A proposta de um espaço colaborativo, entretanto, permanece. Para que possamos manter uma lista ampla e atualizada da bibliografia produzida sobre estes povos, convidamos todas as pessoas interessadas a indicarem bibliografia para disponibilização na plataforma. As referências e arquivos devem ser enviados para o nosso e-mail: karajadigital@gmail.com.
                </p>

                <img className={styles.image} src={CasaImage} />

                <p className={styles.paragraph}>
                    Além da bibliografia, a plataforma conta também com um acervo fotográfico dedicado à documentação da produção cerâmica karajá, sobretudo as famosas bonecas ritxoko. No futuro, esperamos expandir a biblioteca para abrigar novos tipos de conteúdo.
                </p>

                <p className={styles.paragraph}>
                    Estimulando o espírito colaborativo, nosso desejo é que a biblioteca karajádigital possa se constituir como uma base de dados extensa e atualizada sobre os Inỹ, difundindo o conhecimento sobre esses povos e estimulando novos pesquisadores a mergulharem no universo fascinante desse povo!
                </p>

                <p className={styles.paragraph}>
                    Obrigado e abraços.
                </p>

                <img className={styles.image} src={PessoaImage} />

            </div>

            <Footer />

        </div>
    )
}