import styles from './styles/global.module.css';
import axios from "axios";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify'
import Home from './pages/Home';
import Login from './pages/Login';
import AreaDoColaborador from './pages/AreaDoColaborador';
import CadastrarUsuario from './pages/CadastrarUsuario';
import CadastrarFotografia from './pages/CadastrarFotografia';
import CadastrarLivro from './pages/CadastrarLivro';
import ListaBibliografica from './pages/ListaBibliografica';
import AcervoFotografico from './pages/AcervoFotografico';
import CategoriaPage from './pages/CategoriaPage';
import Testes from './pages/Testes';
import MeuComponente from './components/MeuComponente';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import NavBar from './components/Navbar';

function App() {

  const { logged, setLogged } = useAuth();
  const { loading, setLoading} = useAuth();
  const { token, setToken} = useAuth();

  if (loading) {
    return <div className={styles.body}>Carregando...</div>; // Exibe um indicador de carregamento enquanto o token está sendo recuperado
  }

  return (
    <div className={styles.body}>
      {/* <AuthProvider> */}
        <BrowserRouter>
          <NavBar />
          <Routes>
            {logged ? (
              <>
                <Route path='/testes' element={<Testes />} />
                <Route path='/area-do-colaborador' element={<AreaDoColaborador />} />
                <Route path='/area-do-colaborador/cadastrar-usuario' element={<CadastrarUsuario token={token} />} />
                <Route path='/area-do-colaborador/cadastrar-publicacao' element={<CadastrarLivro token={token} />} />
                <Route path='/area-do-colaborador/cadastrar-fotografia' element={<CadastrarFotografia token={token} />} />
              </>
            ) : (
              <>
              </>
            )}
            <Route path='/login' element={<Login />} />
            <Route path='/lista-bibliografica' element={<ListaBibliografica />} />
            <Route path='/acervo-fotografico' element={<AcervoFotografico />} />
            <Route path='/acervo-fotografico/:categoria' element={<CategoriaPage />} />
            <Route path='/' element={<Home />} />
            <Route path='/login' element={<Login />} />
            <Route path='*' element={<Navigate to='/' />} />
          </Routes>

          <ToastContainer
            position="top-right"
            autoClose={5000}
            limit={3}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
        </BrowserRouter>
      {/* </AuthProvider> */}
    </div>
  );
}

export default App;
