import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from './Navbar.module.css'
import { useNavigate } from "react-router-dom";
import IconKaraja from "../assets/karaja_icon_login.png"
import { BiSolidExit } from "react-icons/bi";
import { AuthProvider, useAuth } from '../contexts/AuthContext';
import { toast } from "react-toastify";

export default function NavBar() {

  const { token, setToken } = useAuth();
  const { logged, setLogged } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    console.log("Valor de logged obtido (NavBar.js):", logged);
  }, [logged]);

  const handleLogout = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_LINK}/logout`,
        {},
        {
          withCredentials: true
        }
      );
      navigate('/login');
      setLogged(false);
      setToken(null);
      toast.success(response.data.message)

    } catch (error) {
      console.error('Erro ao fazer logout:', error);
      toast.error('Falha ao fazer logout')
    }
  };

  return (
    <div className={styles.Background}>
      <div className={styles.BackgroundNavbar}>
        <div className={styles.ContainerTextLogo}>
          <a className={styles.FontLogo} href='/' alt="Karaja Digital Logo">KARAJA DIGITAL</a>
        </div>
        <div className={styles.LinksContainer}>
          <a className={styles.LinksText} href='/'>INÍCIO</a>
          <a className={styles.LinksText} href='/lista-bibliografica'>LISTA DE BIBLIOGRAFIA</a>
          <a className={styles.LinksText} href='/acervo-fotografico'>ACERVO FOTOGRÁFICO</a>
        </div>
        <div className={styles.ContainerTextAdmin}>
          {!logged ? (
            <a onClick={() => navigate('/login')}>
              <img className={styles.iconLogin} onClick={() => navigate('/login')} src={IconKaraja} />
            </a>
          ) : (
            <>
              <button className={styles.buttonAdm} onClick={() => navigate('/area-do-colaborador')}>Painel administrativo</button>
              <div className={styles.containerLogged} onClick={handleLogout}>
                <p className={styles.fontLogged}>Sair</p>
                <BiSolidExit
                  className={styles.iconLogged}
                  onClick={handleLogout}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
